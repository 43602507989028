"use strict";

// Class Definition
var PGPage = function() {

    
			var counterDotUse = 0;
			var lastDot = 0;
            var rand1 = 0;

			var dotStart = 95;

			var generateDot = function(xstart,ystart,opacity,speed,after){
				var counterDot = counterDotUse;
				var rand = Math.random();
				if(rand < 0.15){
					rand1 = 1;
				}
				if(rand >= 0.15 && rand < 0.3){
					rand1 = 2;
				}
				if(rand >= 0.3){
					rand1 = 0;
				}
				$('#dot-preset'+rand1).clone().attr('id','dot'+counterDot).appendTo('#dot-list');
				$('#dot'+counterDot).css('left',xstart +'%');
				$('#dot'+counterDot).css('top',ystart +'%');
				$('#dot'+counterDot).find('svg').css('width',15+(opacity*20)+'px');
				$('#dot'+counterDot).find('svg').css('height',15+(opacity*20)+'px');
				$('#dot'+counterDot).css('opacity',opacity);
				$('#dot'+counterDot).fadeIn('fast');
				$('#dot'+counterDot).animate({top: '-10%'}, speed, function() {
					$('#dot'+counterDot).remove();
					counterDotUse++;
					if(after == 1){
						generateDot(xstart,dotStart,opacity,speed,after);
					}
				  });
				counterDotUse++;
			}

			var initDots = function(){
				generateDot(20,65,0.8,1000,0);
		    	generateDot(85,5,0.4,2000,0);
		    	generateDot(30,50,0.6,900,0);
		    	generateDot(95,100,0.6,600,0);
		    	generateDot(34,99,0.35,800,0);
		    	generateDot(15,150,0.75,700,0);
		    	generateDot(2,165,0.8,1000,0);
		    	generateDot(79,105,0.4,2000,0);
		    	generateDot(73,150,0.6,1300,0);
		    	generateDot(4,199,0.35,1200,0);

		    	generateDot(10,115,0.8,8000,1);
		    	generateDot(95,105,0.4,10000,1);
		    	generateDot(80,100,0.6,5000,1);
		    	generateDot(5,110,0.35,5000,1);
		    	generateDot(25,120,0.75,6000,1);

		    	generateDot(8,165,0.8,11000,1);
		    	generateDot(90,105,0.4,6000,1);
		    	generateDot(83,150,0.6,8000,1);
		    	generateDot(2,199,0.35,9000,1);
			}



    var getOffset = function(el) {
        const rect = el.getBoundingClientRect();
        return {
          left: rect.left + window.scrollX,
          top: rect.top + window.scrollY
        };
      }

      var magnetx;
    var magnety;
      var mouseX = 0, mouseY = 0;
      

          $( document ).ready(function() {
              magnetx = getOffset(document.getElementById('magnet-effect')).left + ($('#magnet-effect').width() / 2 / 2);
              magnety = getOffset(document.getElementById('magnet-effect')).top - ($('#magnet-effect').height() / 2);
          });
          
          $(document).mousemove(function(e){
             mouseX = e.pageX-5;
             mouseY = e.pageY; 
          });

          // cache the selector
          var magnet = $("#magnet-logo");
          var magnetr = $("#magnet-logo-right");
          var xp = 0, yp = 0;
          var loop = setInterval(function(){
              if($('#magnet-effect:hover').length != 0){
              // change 12 to alter damping higher is slower
              $("#magnet-logo-right").width($("#magnet-logo").width()+'px');
              $("#magnet-logo-right").height($("#magnet-logo").height()+'px');
                  magnetr.width = magnet.width();
                  xp += ((mouseX - xp - getOffset(document.getElementById('magnet-effect')).left - ($('#magnet-effect').width() / 2 / 2)) / 40);
                  yp += ((mouseY - yp - getOffset(document.getElementById('magnet-effect')).top - ($('#magnet-effect').height() / 2)) / 40);
                  if(xp < (0 - $('#magnet-effect').width() / 2 / 2 + $('#magnet-logo').width() / 2 - 6)){
                      xp = -$('#magnet-effect').width() / 2 / 2 + $('#magnet-logo').width() / 2 - 6;
                  }
                  if(yp < (0 - $('#magnet-effect').height() / 2 + $('#magnet-logo').height() / 2)){
                      yp = - $('#magnet-effect').height() / 2 + $('#magnet-logo').height() / 2;
                  }
                  if(xp > ($('#magnet-effect').width() / 2 + $('#magnet-effect').width() / 2 / 2 - $('#magnet-logo').width() / 2 + 15)){
                      xp = $('#magnet-effect').width() / 2 + $('#magnet-effect').width() / 2 / 2 - $('#magnet-logo').width() / 2 + 15;
                  }
                  if(yp > ($('#magnet-effect').height() / 2 - $('#magnet-logo').height() / 2)){
                      yp = $('#magnet-effect').height() / 2 - $('#magnet-logo').height() / 2;
                  }

                  magnet.css({left:xp, top:yp});
                  magnetr.css({left:xp-($('#magnet-effect').width() / 2)+($('#magnet-logo').width()) + 5, top:yp});
              }else{
                  if((xp < 0.01 && xp > -0.01) && (yp < 0.01 && yp > -0.01)){
                      xp = 0;
                      yp = 0;
                  }else{
                      xp = (xp * 0.9);
                      yp = (yp * 0.9);
                  }
                  magnet.css({left:xp, top:yp});
                  magnetr.css({left:xp-($('#magnet-effect').width() / 2)+($('#magnet-logo').width()) + 5, top:yp});
              }
              
          }, 10);
    

    $( ".example-element" ).hover(
        function() {
            this.children[1].children[0].currentTime = 0;
          $(this).find('.example-video').fadeIn('fast');
        }, function() {
          $(this).find('.example-video').fadeOut('fast');
        }
      );


    return {
        init: function () {
            $('.example-element').each(function(i,e){
                $(e).css('height',$(e).find('img').css('height'));
            });

            $.ajax({ 
                type: 'POST', 
                url: GEShared.apiServer()+'homeGetData.php',
                data: { q: "" },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                dataType: 'json',
                success: function (data) { 
                    $('#screens-stat').fadeOut('fast',function(){
                        $('#screens-stat').text(data[2][0]);
                        $('#screens-stat').fadeIn('fast');
                    });
                    $('#time-stat').fadeOut('fast',function(){
                        $('#time-stat').text(data[2][1]);
                        $('#time-stat').fadeIn('fast');
                    });
                    $('#uptime-stat').fadeOut('fast',function(){
                        $('#uptime-stat').text(data[2][2]);
                        $('#uptime-stat').fadeIn('fast');
                    });
                    data[2][3]['reviews'].forEach(function(item,index){
                        $('#review-template').clone().attr('id','review'+index).appendTo($('#reviewelements'));
                        $('#review'+index).find('.review-name').text(item.name);
                        $('#review'+index).find('.review-text').html(item.value);
                        $('#review'+index).find('.review-link').attr('href',item.link);
                        $('#review'+index).find('.review-date').text(item.date);
                        if(item.logo == ""){
                            $('#review'+index).find('.review-logo').parent().removeClass('align-items-end');
                            $('#review'+index).find('.review-logo').parent().html(item.name[0]);
                        }else{
                            $('#review'+index).find('.review-logo').attr('src',item.logo);
                        }
                        $('#review'+index).fadeIn('fast');
                        if(item.stars > 0){
                            $('#review'+index).find('.review-star-1').addClass('checked');
                        }
                        if(item.stars > 1){
                            $('#review'+index).find('.review-star-2').addClass('checked');
                        }
                        if(item.stars > 2){
                            $('#review'+index).find('.review-star-3').addClass('checked');
                        }
                        if(item.stars > 3){
                            $('#review'+index).find('.review-star-4').addClass('checked');
                        }
                        if(item.stars > 4){
                            $('#review'+index).find('.review-star-5').addClass('checked');
                        }

                    });

                }
            });
            GEShared.loadUserData();
        },
        handleUserData: function(data){
            initDots();
            setTimeout(function(){  $('#preloader').fadeOut(800); }, 800);
        }
    }
}();

// On document ready
KTUtil.onDOMContentLoaded(function() {
    PGPage.init();
});